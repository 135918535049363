import './css/style.css';

import { Link, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';

import _ from 'underscore';

import FrontPage from './components/FrontPage';
import ExhibitionPageView from './components/ExhibitionPageView';
import ExhibitionPageHeader from './components/ExhibitionPageHeader';
import ExhibitionSubpageView from './components/ExhibitionSubpageView';
import ImagePreloader from './components/ImagePreloader';
import Footer from './components/Footer';
import FrontPageContent from './components/FrontPageContent';
import GlobalAudioPlayer from './components/GlobalAudioPlayer';
import HotspotGenerator from './components/HotspotGenerator';
import ManuscriptOverview from './components/ManuscriptOverview';

import Icon from './components/elements/Icon';
import PageContent from './components/elements/PageContent';
import logo from './img/arnastofnun-logo.png';

import config from './config';
import AppMenu from './components/AppMenu';
import Page from './components/Page';
import PlinthMenuWrapper from './components/PlinthMenuWrapper';

import GoogleAnalytics from './components/GoogleAnalytics';

function App() {
	const [siteData, setSiteData] = useState(null);
	const [frontPageData, setFrontPageData] = useState(null);

	const [currentLang, setCurrentLang] = useState('is');
	const [windowPos, setWindowPos] = useState(null);
	const [lightIntensity, setLightIntensity] = useState(0);

	const [hoverBg, setHoverBg] = useState(null);
	const [showExtraMenu, setShowExtraMenu] = useState(false);

	const [initialized, setInitialized] = useState(true);

	const [themeColor, setThemeColor] = useState(null);

	const [currentLocation, setCurrentLocation] = useState('');

	const navigate = useNavigate();
	const location = useLocation();

	const frontPageSectionRef = useRef({});
	const headerButtons = useRef(null);

	const isScrolledIntoView = (el) => {
		var rect = el.getBoundingClientRect();
		var elemTop = rect.top;
		var elemBottom = rect.bottom;
	
		var isVisible = (elemTop+20 >= 0) && (elemBottom <= window.innerHeight);

		return isVisible;
	}

	useEffect(() => {
		if (window && window.location && window.location.hostname.toLowerCase().indexOf('worldinwords.is') > -1) {
			setLang('en');
		}

		if (!siteData) {
			fetch(config.apiRoot+'chapters/')
				.then(res => res.json())
				.then(json => setSiteData(json.results));
		}
		if (!frontPageData) {
			fetch(config.apiRoot+'frontpage/')
				.then(res => res.json())
				.then(json => setFrontPageData(json.results));
		}

		if (!window.windowMouseMove) {
			window.windowMouseMove = (event) => {
				setWindowPos({
					x: event.clientX,
					y: event.clientY
				});
			};

			window.addEventListener('mousemove', window.windowMouseMove);
		}

		setTimeout(() => {
			if (!window.windowScrollHandler) {
				window.windowScrollHandler = () => {
					if (headerButtons.current) {
						setShowExtraMenu(!isScrolledIntoView(headerButtons.current))
					}
				};

				window.addEventListener('scroll', window.windowScrollHandler, true);
			}
		}, 500);

		try {
			if (window.localStorage.getItem('currentLang')) {
				setCurrentLang(window.localStorage.getItem('currentLang'));
			}
			else {
				window.localStorage.setItem('currentLang', 'is');
			}
		}
		catch(e) {}

		setTimeout(() => setInitialized(true), 1300);
	}, []);

	useEffect(() => {
		if (location.pathname == '/') {
			setThemeColor(null);
		}

		if (location.pathname.split('/').length < 5) {
			window.scrollTo({top: 0, behavior: 'smooth'});
			setShowExtraMenu(false);
		}

		setCurrentLocation(location.pathname);

		if (location.hash.length > 1) {
			scrollToElement(null, location.hash.replace('#', ''));
		}
	}, [location]);

	const scrollToElement = (event, refName) => {
		if (event) {
			event.preventDefault();
		}

		if (frontPageSectionRef.current && frontPageSectionRef.current[refName]) {
			frontPageSectionRef.current[refName].scrollIntoView({
				behavior: 'smooth'
			});
		}
		else {
			setTimeout(() => {
				if (frontPageSectionRef.current && frontPageSectionRef.current[refName]) {
					frontPageSectionRef.current[refName].scrollIntoView({
						behavior: 'smooth'
					});
				}
			}, 1000);
		}
	};

	const setLang = (lang) => {
		setCurrentLang(lang);

		try {
			window.localStorage.setItem('currentLang', lang);
		}
		catch(e) {}
	}

	const urlBits = (url) => _.compact(url.split('/'));

	return <div className={'wrapper level-'+(urlBits(location.pathname).length)+' section-'+urlBits(location.pathname)[0]}>
			<div className={'app relative'}>

			<div className="left-border bg-sam_red transition-all duration-[800ms] " style={themeColor ? {
				'backgroundColor': themeColor
			} : null}>
				<div className="upper bg-sam_red z-[600] transition-all duration-[800ms] " style={themeColor ? {
					'backgroundColor': themeColor
				} : null}></div>
				<div className="lower bg-sam_red z-[600] transition-all duration-[800ms] " style={themeColor ? {
					'backgroundColor': themeColor
				} : null}></div>
			</div>



			<AppMenu frontPageData={frontPageData} siteData={siteData} currentLang={currentLang} onLangChange={(lang) => {
				setLang(lang);
				navigate('/');
			}} />

			<div className="absolute top-5 left-4 md:left-[120px] md:left-[120px] z-20 md:flex items-center">

				<Link to={'/'}><img src={logo} className="hidden md:block w-[200px]" /></Link>

				<div className={'hidden md:block absolute left-2 text-nowrap md:left-[210px] w-full md:border-l border-white/50 text-white mt-6 md:mt-2 ml-4 md:ml-5 pl-0 md:pl-8 text-xl transition-[opacity,transform] duration-500'+(((!location.pathname.startsWith('/'+config.exhibitionPageRoot) && location.pathname != '/') || location.pathname  == '/'+config.exhibitionPageRoot) ? '' : ' translate-y-10 opacity-0 pointer-events-none')}><Link to="/">{window.l('Forsíða')}</Link></div>
				<div className={'absolute left-2 text-nowrap md:left-[210px] w-full md:border-l border-white/50 text-white mt-6 md:mt-2 ml-4 md:ml-5 pl-0 md:pl-8 text-xl transition-[opacity,transform] duration-500'+(location.pathname.startsWith('/'+config.exhibitionPageRoot) && location.pathname != '/'+config.exhibitionPageRoot ? '' : ' translate-y-10 opacity-0 pointer-events-none')}><Link to={'/'+config.exhibitionPageRoot}>{window.l('Heimur í orðum')}</Link></div>

			</div>

			<Routes>

				<Route exact path={config.exhibitionPageRoot} element={<>
						<FrontPage initialized={initialized} 
							currentLang={currentLang} 
							windowPos={windowPos}
							tiltMode={2}
							headerContent={<PlinthMenuWrapper currentLang={currentLang} 
								windowPos={windowPos} 
								initialized={initialized} 
								siteData={siteData}
								plinthMouseEnter={(event, item) => {
									setHoverBg(item.image);
									setLightIntensity(100);
								}} 
								plinthMouseLeave={(event, item) => {
									setHoverBg(null);
									setLightIntensity(30);
								}}
								plinthClick={(event, item) => {
									navigate('/syning'+item.path);
									setLightIntensity(30);
								}} />
							}
						></FrontPage>

						<Footer />

					</>}
				/>

				{
					siteData && siteData.map((chapter, index) => <Route key={index} exact path={config.exhibitionPageRoot+'/'+chapter.path} element={<>
							<ExhibitionPageView currentLang={currentLang} 
								siteData={siteData}
								headerContent={() => <ExhibitionPageHeader title={chapter['title_'+currentLang]} 
									currentLang={currentLang}
									subTitle={chapter['subtitle_'+currentLang] ? chapter['subtitle_'+currentLang] : null}
									headerText={chapter['info_'+currentLang]}
									chapter={chapter}
									headerLinks={
										chapter.pages ? chapter.pages.map(page => {
											return {
												url: page.path+(page.subpages && page.subpages.length > 1 ? '/'+page.subpages[0].path : ''),
												title_is: page.title_is,
												title_en: page.title_en,
												hasSubpage: page.subpages && page.subpages.length > 1
											}
										}) : null
									}
								/>}
								headerBackground={chapter.header_background ? {
									url: chapter.header_background,
									type: 'image'
								} : chapter.header_background_video ? {
									url: chapter.header_background_video,
									type: 'video'
								} : null}
								mainContent={<>
								</>}
								themeColor={chapter.theme_color}
								onThemeColor={(color) => setThemeColor(color)}
							/>

							<Footer />

						</>
					}>
						{
							chapter.pages && chapter.pages.map((page, sIndex) => <Route key={sIndex} exact path={page.path+'/*'} element={<>
								<ExhibitionSubpageView currentLang={currentLang} 
									pagePath={page.path}
									headerBackground={page.header_background ? {
										url: page.header_background,
										type: 'image'
									} : page.header_background_video ? {
										url: page.header_background_video,
										type: 'video'
									} : null}
									manuscriptImage={
										/*
										page.chapter_page_manuscripts.length > 0 ? {
											url: page.chapter_page_manuscripts[0].manuscript_image,
											shelfmark: page.chapter_page_manuscripts[0].manuscript,
											text: {
												is: page.chapter_page_manuscripts[0].shortinfo_is,
												en: page.chapter_page_manuscripts[0].shortinfo_en
											}
										} : null
										*/
										page.chapter_page_manuscripts.length > 0 ? page.chapter_page_manuscripts[0] : null
									}
									subPageTitle={page['title_'+currentLang]}
									subPageInfo={page['info_'+currentLang]}
									subPages={page.subpages ? page.subpages.map(subPage => {
										return {
											path: subPage.path,
											title: subPage['title_'+currentLang],
											content: <PageContent currentLang={currentLang} pageId={subPage.id} subPage={true} />
										}
									}) : null}
								>
									{
										<PageContent currentLang={currentLang} pageId={page.id}/>
									}
								</ExhibitionSubpageView>

							</>} />)
						}
					</Route>)

				}

				<Route path="hotspots" element={<>
					<HotspotGenerator />
				</>} />

				<Route exact path="/" element={<>
					<FrontPage initialized={initialized} 
						currentLang={currentLang} 
						windowPos={windowPos}
						showContent={true}
						isFront={true}
						tiltMode={1}
						largeHeader={true}
						headerContent={<>

							<div className={'container text-white mx-auto mt-10 z-10 transition-[opacity,transform] duration-[300ms] '+(initialized ? ' opacity-100' : ' opacity-0')}>
								<div className={'inner-container lg:absolute lg:bottom-38 2xl:bottom-48'}>
									

									<div className="page-header-buttons" ref={headerButtons}>
										{
											frontPageData && _.where(frontPageData, {in_menu: true}).map((item, index) => <a key={index} href={'#'+item.ref_name} className="tab underlined" 
												onClick={(event) => {
													if (item.ref_name) {
														scrollToElement(event, item.ref_name);
													}
												}}>{item['name_'+currentLang]}</a>)
										}
										<Link className="tab underlined" to="/handrit">{window.l('Handritin')}</Link>
										{
											currentLang != 'en' &&
											<Link className="tab px-5 bg-sam_accent_dark text-white rounded" to="/syning">{window.l('Lærðu meira')}</Link>
										}
									</div>
								</div>
							</div>

						</>}
					>
						
						<div className={'hidden md:hidden inner-container page-header-buttons bg-sam_light/80 dark sticky top-0 py-5 z-10'+(showExtraMenu ? ' opacity-100' : ' opacity-0 pointer-events-none')}>
							{
								frontPageData && _.where(frontPageData, {in_menu: true}).map((item, index) => <a key={index} className="tab dark underlined" 
									onClick={(event) => {
										if (item.ref_name) {
											scrollToElement(event, item.ref_name);
										}
									}}>{item['name_'+currentLang]}</a>)
							}
							<Link className="tab dark underlined" to="/handrit">{window.l('Handritin')}</Link>
							<Link className="tab px-5 bg-sam_accent_dark text-white rounded" to="/syning">{window.l('Sýningin')}</Link>
						</div>
						<FrontPageContent data={frontPageData} currentLang={currentLang} refObj={frontPageSectionRef} />
					</FrontPage>

					<Footer whiteLogo={true} />

				</>} />

				<Route exact path={'/handrit/:shelfmark?'} element={<>
					<FrontPage initialized={initialized} 
						showContent={true}
						isSubPage={true}
						currentLang={currentLang} 
						windowPos={windowPos}
						tiltMode={3}
						headerContent={<>
							{/*
							<div className={'container text-white mx-auto -mt-10 transition-[opacity,transform] duration-[300ms] '+(initialized ? ' opacity-100' : ' opacity-0')}>
								<div className={'inner-container'}>
									

									<div className="page-header-buttons relative z-20">
										<a className="tab underlined" onClick={() => navigate(-1)}><Icon className="inline-block" icon={'arrow-left'} /> {window.l('Til baka')}</a>
									</div>
								</div>
							</div>
							*/}
						</>}
					>
						<ManuscriptOverview currentLang={currentLang} />
					</FrontPage>

					<Footer whiteLogo={true} />

				</>} />

				<Route exact path={'*'} element={<>
					<FrontPage initialized={initialized} 
						showContent={true}
						isSubPage={true}
						currentLang={currentLang} 
						windowPos={windowPos}
						tiltMode={3}
						headerContent={<>

							<div className={'container text-white mx-auto -mt-10 z-10 transition-[opacity,transform] duration-[300ms] '+(initialized ? ' opacity-100' : ' opacity-0')}>
								<div className={'inner-container'}>
									

									<div className="page-header-buttons">
										<Link className="tab underlined" to="/"><Icon className="inline-block" icon={'arrow-left'} /> {window.l('Til baka á forsíðu')}</Link>
									</div>
								</div>
							</div>

						</>}
					>
						<Page currentLang={currentLang} />
					</FrontPage>

					<Footer whiteLogo={true} />

				</>} />

			</Routes>

			<div className={'absolute top-0 left-0 right-0 bottom-0 pointer-events-none bg-center bg-cover transition-all '+(hoverBg ? 'opacity-0' : 'opacity-0')} style={hoverBg ? {
					backgroundImage: 'url(/img/'+hoverBg+')'
				} : null
			}></div>

			{
				siteData &&
				<ImagePreloader data={siteData} />
			}

			<div className={'fixed top-0 left-0 mouse-backlight transition transition-[opacity,transform] duration-1000 ease-in-out '+(lightIntensity == 100 ? 'opacity-100 scale-150' : lightIntensity == 40 ? 'opacity-60 scale-125' : 'opacity-20')} style={
				windowPos ? { top: windowPos.y-300, left: windowPos.x-300 } : null
			}></div>

			<GlobalAudioPlayer pathname={location.pathname} />

		</div>

		<GoogleAnalytics />

	</div>
}

export default App;
